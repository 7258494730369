<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Proxy Bandwidth Report</h2>
        <div class="row mb-3">
            <div class="col-12 text-right">
                <div class="form-inline">
                    <div class="form-group mr-1">
                        <input type="text" v-model="filter.name" class="form-control" placeholder="Search......"/>
                    </div>
                    <div class="form-group mr-1">
                        <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                    </div>
                    <div class="form-group mr-1">
                        <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                    </div>
                    <div class="form-group mr-1">
<!--                        <v-select-->
<!--                            style="width: 200px; background: white"-->
<!--                            v-model="filter.provider"-->
<!--                            :options="providers"-->
<!--                            multiple-->
<!--                            :placeholder="providerPlaceHolder"-->
<!--                        >-->
<!--                            <template slot="no-options">-->
<!--                                &#45;&#45; Any Provider &#45;&#45;-->
<!--                            </template>-->
<!--                        </v-select>-->
                        <select v-model="filter.provider" class="form-control">
                            <option value="" selected>-- Any Campaign --</option>
                            <option v-for="provider in providers" :key="provider._id" :value="provider._id">{{provider._id}}</option>
                        </select>
                    </div>
                    <button
                        type="button"
                        class="btn btn-primary mr-1"
                        @click="search"
                    >
                        <i class="fa fa-search" title="Search"></i>
                        Search
                    </button>
                    <button
                        type="button"
                        class="btn btn-success mr-1"
                        v-if="showExportButton"
                        @click="exportToExcel"
                    >
                        <i
                            class="fa fa-download"
                            title="Export"
                        >
                        </i>
                        Export
                    </button>
                </div>
            </div>
        </div>
        <div class="row mb-12" v-if="reportLoading">
            <div class="col-12">
                <div class="card">
                    <div class="card-body text-center">
                        <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                        <h4>Processing..............</h4>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-12">
                <ejs-grid
                    ref="grid"
                    id="Grid"
                    :dataSource="report"
                    :allowExcelExport='true'
                    :allowPaging="true"
                    :allowSorting="true"
                    :allowGrouping='true'
                    :allowFiltering="true"
                    :groupSettings='groupSettings'
                    :filterSettings="filterSettings"
                    :pageSettings='pageSettings'
                >
                    <e-columns>
                        <e-column field='date' headerText="Date"></e-column>
                        <e-column field='time_frame' headerText='Time Frame'></e-column>
                        <e-column field='provider' headerText='Provider'></e-column>
                        <e-column field='id' headerText='Proxy ID' :template="pTemplate"></e-column>
                        <e-column field='data_ip' headerText='IP'></e-column>
                        <e-column field='data_country' headerText='Country'></e-column>
                        <e-column field='data_state' headerText='State'></e-column>
                        <e-column field='data_city' headerText='City'></e-column>
                        <e-column field='error_code' headerText='Error Code'></e-column>
                        <e-column field='error_count' headerText='Error Count'></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert";
// import VSelect from 'vue-select';
import Vue from "vue";
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Group,
    Filter
} from "@syncfusion/ej2-vue-grids";
import pTemplate from "@/components/pTemplate";
import sha1 from "js-sha1";


Vue.use(GridPlugin);

export default {
    name: 'ProxyReport',
    props:['user'],
    components:{},
    data: function () {
        return {
            filter:{
                to: '',
                from: '',
                provider:"",
                name: ""
            },
            report:[],
            providers:[],
            providerPlaceHolder:"-- Loading --",
            proxyDataset: {},
            pageSettings: {pageSize: 100},
            groupSettings:  {
                showDropArea: false,
                disablePageWiseAggregates: true,
                columns: ['date', 'provider','time_frame']
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            searching: false,
            showExportButton: false,
            reportLoading: false,
            pTemplate: function () {
                return {
                    template : pTemplate
                }
            }
        }
    },
    created: function(){
        this.$root.isAuth();
        this.load();
        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter
        ]
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        actionHandler: function(args) {
            console.log(args);
        },
        load: function () {
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());
            this.getProviders();
        },
        search: function(){
            if(new Date(this.filter.from) < this.getDateDifference()){
                swal({title:'Oops', text: `Out of range, please select dates within 2 months.`, icon: 'error'})
                return;
            }
            this.searching = true;
            this.reportLoading = true;
            this.showExportButton = false;
            this.proxyDataset = {};
            this.report = [];

            var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};

            if(this.filter.from) request.filters.from = this.filter.from;
            if(this.filter.to) request.filters.to = this.filter.to;
            if(this.filter.provider) request.filters.provider = this.filter.provider;
            if(this.filter.status) request.filters.status = this.filter.status;

            axios.get(`${this.$root.serverUrl}/admin/proxies/bandwidth`,{params:request}).then((resp) => {
                if(resp.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                }else{
                    const result = resp.data.data;
                    if(result.length > 0){
                        for (let i in result) {
                            this.stage(result[i]);
                        }
                        for(const item in this.proxyDataset){
                            this.report.push(this.proxyDataset[item]);
                        }
                        this.showExportButton = true;
                        this.reportLoading = false;
                    }else{
                        this.reportLoading = false;
                    }
                }
                this.searching = false;
            }).catch((err) => {
                this.reportLoading = false;
                this.searching = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            });
        },
        stage: function (dataset) {
            const geoHash = this.hashGeoTracking(
                dataset.data.country,
                dataset.data.state_name,
                dataset.data.city,
                dataset.data.ip
            );
            const errorHash = this.hashErrorTracking(geoHash,dataset.errorCode);
            if (!this.proxyDataset.hasOwnProperty(errorHash)) {
                this.proxyDataset[errorHash] = {
                    id: dataset.proxy_id,
                    provider: dataset.provider,
                    time_frame: this.getTimeFrame(dataset.date),
                    date: this.getDate(dataset.date),
                    error_code:dataset.errorCode,
                    error_count:0,
                    data_country:dataset.data.country || 'N/A',
                    data_state:dataset.data.state_name || 'N/A',
                    data_city:dataset.data.city || 'N/A',
                    data_ip:dataset.data.ip || 'N/A',
                    details:[]
                };
            }
            this.proxyDataset[errorHash].error_count++;
            this.proxyDataset[errorHash].details.push({
                id:dataset._id,
                date:this.getDate(dataset.date),
                time:this.getTime(dataset.date),
                provider:dataset.provider,
                error_text:dataset.errorText,
                status: dataset.type,
                url:dataset.url,
                headers: JSON.stringify(dataset.headers,null,'  '),
                request_headers: JSON.stringify(dataset.requestHeaders,null,'  '),
                data_ip:dataset.data.ip,
                data_port:dataset.data.port,
                data_username:dataset.data.username,
                data_password:dataset.data.password,
                data_status:dataset.data.status,
                data_protocol:dataset.data.protocol,
                data_country: dataset.data.country,
                data_state:dataset.data.state_name,
                data_city:dataset.data.city,
                data_weight: dataset.data.weight
            });
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign} ${this.filter.client}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.report,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        },
        getDateFormatted: function(d){
            var
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        },
        getDateDifference: function(){
            const toDate = new Date(this.filter.to);
            let fromDate = new Date(this.filter.to);
            //fromDate.setDate(toDate.getDate()+1);  //day was off by a day
            fromDate.setMonth(toDate.getMonth()-2); //set two months in the past
            return fromDate;
        },
        fixDates: function(){
            this.filter.from = this.getDateFormatted(this.getDateDifference());
        },
        hashGeoTracking: function(country,state,city,ip){
            return sha1(`${country}${state}${city},${ip}`);
        },
        hashErrorTracking: function(geoHash,errorCode){
            return sha1(`${geoHash}${errorCode}`);
        },
        getProviders: function(){
            setTimeout(function(){
                this.providers = [
                    { label: 'Didsoft (Active)', _id: 'didsoft' },
                    { label: 'Crawlera (Active)', _id: 'Crawlera' },
                    { label: 'Flipnode (Active)', _id: 'Flipnode' },
                    { label: 'Homeip (Active)', _id: 'Homeip' },
                    { label: 'Luminate (Active)', _id: 'Luminate' },
                    { label: 'Proxyrack (Active)', _id: 'Proxyrack' }
                ];
                this.providerPlaceHolder = "-- Any Provider --";
            }.bind(this),2000);
        }
    }
}
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>
